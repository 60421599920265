import * as React from "react";
import Layout from "../components/layout";


const Privacy = () => {
  return (
    <Layout>
      <section>
      </section>
    </Layout>
  );
};

export default Privacy;
